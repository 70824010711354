import { formatGeneral } from 'cleave-zen';
import ClipboardJS from 'clipboard';

['name', 'departament', 'phone', 'ramal'].map((field) => {
  const el = document.querySelector(`#js-${field}`);

  el.addEventListener('input', (e) => {
    const data = document.querySelector(`[data-field-${field}]`);

    if (field === 'ramal') {
      data.style.display = !e.target.value ? 'none' : 'inline';
    }

    if (field === 'phone') {
      el.value = formatGeneral(e.currentTarget.value, {
        blocks: el.value.length > 14 ? [0, 2, 5, 4] : [0, 2, 4, 4],
        delimiters: ['(', ') ', '-'],
        delimiterLazyShow: true,
        numericOnly: true,
      });
    }

    data.innerHTML = field === 'ramal' ? `- ${e.target.value}` : e.target.value;
  });
});

const button = new ClipboardJS('#js-copy-button');

button.on('success', (event) => {
  event.trigger.classList.toggle('btn-success');
  event.trigger.textContent = 'Copiado';

  setTimeout(() => {
    event.trigger.classList.toggle('btn-success');
    event.trigger.textContent = 'Copiar assinatura';
  }, 2000);

  event.clearSelection();
});
